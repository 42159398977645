@import url('https://fonts.googleapis.com/css2?family=Lustria&display=swap');

*{
    padding: 3px;
    margin:0;
    box-sizing:border-box;
    font-family: 'Lustria', serif;
}

:root{
    --mainColor:#abc8e6;
    --secondColor:--#383e72;
    --thirdColor:--#ffffff;
    --fourthColor:--#454e63;
    --textColor:#34729b;
    --secondaryTextColor:#b4763c;
    --bgcolor1: #383E72;
    --menucolor1: #e1edfa;
    --clr-border1: #abc8e6;
    --clr-bg-tertiary:  rgb(255, 255, 255);
    /*object colours*/
    --clr-button: #ffffff;
    --clr-button-hover: #2b39ff;
    --clr-button-top: #40f2ff;
    --clr-button-text: #ffffff;
    /*text colours*/    
    --clr-text-black: rgb(0, 0, 2);
    --clr-text-white: rgb(255, 255, 255);
    --clr-text-darkblue: rgb(0, 5, 54);
    --clr-text-mint: rgb(245, 255, 250);
    --clr-text-gray: #333;
    /*accents and hover*/
    --clr-accent: #F7F7FF;
    --clr-highlight: #ffffff;
}
/*============================body===========================*/
html{scroll-behavior: smooth;}
/*=============================alignment======================*/
.centered {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.contents-right{margin-left: auto; margin-right: 0;}
.contents-left{margin-right: auto; margin-left: 0;}

/*===========================button========================*/
.button {
  display: block;
  width: 115px;
  height: 25px;
  background: #4E9CAF;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
}
#button{ 
  border-color: var(--clr-white); 
  color: var(--clr-bg-tertiary);
  border-style: solid; 
  border: 6px; 
  border-radius: 5px; 
}
#top-button{ 
  font-size: 2vw; 
  padding: 0 1em; margin: 0 auto; 
  border-radius: 25px; 
  background: var(--clr-button); 
  cursor: pointer;
}
#top-button:hover{ color: var(--clr-highlight); background: var(--clr-button-hover)}
#sendButton:hover{background-color: #78848b;}
#sendButton{
  background-color: #34729b;
  font-family: 'Lustria', serif;
  color: white;
  font-weight: 200;
  border-radius: 5px; 
}
/*==================================markup========================*/

.hr-a {
  margin: 1rem 0;
  color: var(--textColor);
  border: 0;
  border-top: 10px solid;
  opacity: 0.25;
}
.hr-b {
  margin: 1rem 0;
  color: var(--secondColor);
  border: 0;
  border-top: 10px solid;
  opacity: 0.25;
}

/*=================================== font colors==============================*/
/* main font color and design*/
.font-main {
    margin-left: 2rm;
    padding: 1rm;
    background-color: #383e72;
    color: #000000;
  }
.font-a1 {
    margin-left: 2rm;
    padding: 1rm;
    background-color: #383e72;
    color: #ffffff;
  }
.font-a11 {
    font-size: 1.75vw !important;
    margin-left: 1rm;
    padding: 1rm;
    color: #383E72;
    font-weight: bolder;
    
  }
  @media screen and (max-width: 767px) {
    .font-a31{
      font-size: 2vw !important;
    }
  }

.font-a12 {
    margin-left: 2rm;
    padding: 1rm;
    color: #454e63;
    font-weight: bolder;
    font-size: 2em;
  }
.font-a13 {
    margin-left: 2rm;
    padding: 1rm;
    color: #34729b;
    font-weight: bolder;
    font-size: 2em;
  }
.font-a2 {
    margin-left: 1rm;
    padding: 1rm;
    color: #383e72;
    font-weight: bolder;
    font-size: 2em;

  }
.font-a21 {
    margin-left: 1rm;
    padding: 1rm;
    color: #383e72;
    font-weight: bolder;
    font-size: 1.75vw;
  }
  .font-a22 {
    margin-left: 1rm;
    padding: 1rm;
    color: #000000;
    font-weight: bolder;
    font-size: 1em;
  }
  .font-a3 {
    margin-left: 1rm;
    padding: 1rm;
    color: #ffffff;
    font-size: 2em;
    background-color: #b4763c;
    border-radius: 25px;
    vertical-align: sub;
  }
  .font-a31 {
    margin-left: 1vh;
    padding: 1vh;
    color: #ffffff;
    font-size: 3vw;
    background-color: #383e72;
    border-radius: 25px;
    vertical-align: sub;
  }
  .font-a32 {
    font-size: 2em;
    border-radius: 25px;
    border-style: double; 
    border-color:var(--bgcolor1);
  }
  .font-a33 {
    font-size: 1.25vw;
    padding-left: 1vw;

  }
  .font-a34 {
    margin-left: .25rm;
    padding: .25rm;
    color: #383e72;
    font-weight: bolder;
    font-size: 1vw;
  }
 .font-right{
    text-align: right;
 } 
 .font-left{
    text-align: left;
 } 
  .font-centered {
    text-align: center;
  }

/*================================ backgrounds=========================*/
img.logomain1{
    border-color: #383E72;
    border-style: solid;
    border-width: 3px;
    border-radius: 25px;
}
div.bg2{
    background-image: url(../media/layeredpeaks.png);
    background-size: cover;
    border-radius: 25px;
}
div.bg3{
    background-image: url(../media/icongreenhouse.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
}
div.bg4{
    background-image: url(../media/iconoilicon.jpg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 30vh;
}
div.bg5{
    background-image: url(../media/utilityBox.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 30vh;
}
div.bg6{
    background-image: url(../media/icongrainbin3.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 30vh;
}

div.bg9{
    background-image: url(../media/wordlogo4.svg);
    background-size: cover;
    border-radius: 25px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    min-height:100px !important;
    max-height:100px !important;
    border: 1px solid ;
    border-color: var(--clr-border1);
}
div.bg10{
    background-image: url(../media/iconhandshake3.png);
    border-radius: 25px;
    background-size: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 35vh;
}

@media screen and (max-width: 767px) {
.hidden-pic{
    visibility: hidden;
  }
}

div.bg11{
    background-image: url(../media/iconmission.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 30vh;

}
div.bg12{
    background-image: url(../media/iconintegrity.png);
    background-size: cover;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 25px;
    min-height:200px !important;
    max-height: 30vh;
}
div.bg13{
    background-image: url(../media/iconpeople.png);
    background-size: cover;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 25px;
    min-height: 30vh !important;
    max-height: 30vh;
}
div.bg14{
    background-image: url(../media/iconstak.png);
    background-size: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 25px;
    min-height: 30vh !important;
    max-height: 30vh;
}
div.bg15{
    background-image: url(../media/logoMainBW.svg);
    background-size: cover;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 25px;
    background-attachment: fixed;
}
div.bg16{
    background-image: url(../media/iconDisclaimer1.png);
    background-size: cover;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 25px;
    max-height: 30vh;
}
div.bg17{
    background-size: cover;
    background-color: #e1edfa;
    border-radius: 25px;
}

div.bg19{
  background-image: url(../media/feathersrightblues.png);
  background-size: cover;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: auto !important;;
  border-radius: 25px;

}
div.bg20{
  background-image: url(../media/featherstoprightblues.png);
  background-size: cover;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;

}
div.bg21{
  background-image: url(../media/teresa.svg);
  background-size: cover;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;
  min-height: 30vh !important;
  max-height: 30vh;
}
div.bg22{
  background-image: url(../media/anne.svg);
  background-size: cover;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;
  min-height: 30vh !important;
  max-height: 30vh;
}
div.bg23{
  background-image: url(../media/leif.svg);
  background-size: cover;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;
  min-height: 30vh !important;
  max-height: 30vh;
}
div.bg24{
  background-image: url(../media/elias.svg);
  background-size: cover;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;
  min-height: 30vh !important;
  max-height: 30vh;
}

div.bg29{
  background-image: url(../media/Connor.png);
  background-size: cover;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 25px;
  min-height: 30vh !important;
  max-height: 30vh;
}

div.bg25{
  background-image: url(../media/evicon.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  max-height: 30vh;
}
div.colorbg{
  background-color: #b4763c;
}
div.texta{
    color: var(--secondaryTextColor);
    font-size: 2rem;
    align-items: left;
}
div.textb{
    color: var(--textColor);
    font-size: 2rem;
    align-items: right;
}

/*======================HIDE ELEMENT=============================*/

.hide { display: none; transition:0s 180s;}

.show-more:hover + .hide {
  display: block; 
  visibility: visible;
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  animation-duration: 1000ms;
}

.border{ 
  border: 3px solid black;
  border-radius: 25px;
  padding: .25em;
  width: 175px;
  margin: auto;
  background: #f3f3f3;
}

@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@-webkit-keyframes fadeIn {
  
  from { opacity: 0; }
    to { opacity: 1; }
}

@media screen and (min-width: 1280px) {
  .border{
    width: 200px;
    }
  }
/*==============================ANIMATION========================================*/

/*Animation*/
/*Animation fade in*/
.fade-in-image { animation: fadeIn 3s; }
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  /*slide */
  .w3-animate-left{position:relative;animation:animateleft 3s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
  .w3-animate-right{position:relative;animation:animateright 5s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
  .w3-animate-top{position:relative;animation:animatetop 2s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
  .w3-animate-zoom {animation:animatezoom 2s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
  
   /*===============================Navbar===========================*/
  .navbar .navbar-toggler.collapsed .menu-icon-bar {
    opacity: 1;
  }/*

  /*navbar font size*/
  .navbar-dark .navbar-nav .nav-link {
    position: relative;
    color: #fff;
    font-size: 2vh;
  }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
  }
  /*drop down menu*/
  .navbar .dropdown-menu {
    padding: 0;
    background-color: #b4763c;
  }
  .navbar .dropdown-menu .dropdown-item {
    position: relative;
    padding: 10px 20px;
    color: #fff;
    font-size: 2vh;
    border-bottom: 1px solid #abc8e6;
    transition: color 0.2s ease-in;
  }
  .navbar .dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar .dropdown-menu .dropdown-item:hover {
    background: transparent;
    color: #abc8e6;
  }
  .content {
    padding: 120px 0;
  }

  /*small screen nav bar*/
  @media screen and (max-width: 768px) {
    .navbar-brand {
      margin-left: 20px;
    }
    .navbar-nav {
      padding: 0 20px;
      background-color: #454e63;
    }
    .nav-link{
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 30px;
      margin-right: 30px;
    }
    .navbar.fixed-top .navbar-nav {
      background: transparent;
    }
  }

  @media screen and (min-width: 767px) {
    .banner {
      padding: 0 150px;
    }
    .banner h1 {
      font-size: 5rem;
    }
    .banner p {
      font-size: 2rem;
    }
    .navbar-dark .navbar-nav .nav-link {
      padding: 23px 25px;
    }
    .navbar-dark .navbar-nav .nav-link::after {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 30%;
      right: 30%;
      height: 1px;
      background-color: #fff;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      transition: transform 0.1s ease-in;
    }
    .dropdown-menu {
      min-width: 200px;
      -webkit-animation: dropdown-animation 0.3s;
      animation: dropdown-animation 0.3s;
      -webkit-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top;
    }
  }



/*=================== carousel ==========================================*/
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 1px solid ;
  border-color: var(--clr-border1);
  border-radius: 25px;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 200px;
  position: absolute;
  width: 100px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 200px;
  width: 250px;
}